import { getAction, postAction , deleteAction } from '@/api/manage'
// 认领(主流程，除了回款)
const entrust = (params) => getAction("/workflow/claimTask", params);
// 认领(副流程，包括回款)
const claimSub = (params) => getAction("/workflow/claimTask2", params);
// 报单借款人下拉框
const getBorrowerBox = (params) => getAction("/base/getBorrowerBox", params);
// 下拉框条目查询
const getByTypeCode = (params) => getAction("/dict/getByTypeCode", params);
// 多个下拉框条目查询
const getByTypeCodes = (params) => getAction("/dict/getByTypeCodes", params);
//拒单接口
const refuseFlow = (params) => postAction("/workflow/refuseFlow",params);
//订单状态
const getMainRecordsByOid = (params) => getAction("/optRecord/getMainRecordsByOid",params);
//文件上传
const fileInsert = (params) => postAction("/ossFile/insertList",params);
//文件删除
const deleteFile = (params) => deleteAction("/ossFile/delete",params);
//oss授权
const ossAuth = (params) => getAction("/ossFile/policy",params);
//文件树
const treeVo = (params) => getAction("/fileTree/treeVo",params);
//获取用户信息 
const getUserInfo = (params) => getAction("@/sys/user/userInfo",params);
//根据token和菜单标识获取当前用户的菜单
const getMenuTree = (params) => getAction("@/sys/menu/getMenuTree",params);
//根据oid和节点获取文件
const getFilesByOidAndKey = (params) => getAction("/ossFile/getFilesByOidAndKey",params);
//获取机构团队
const getTeams = (params) => getAction("@/sys/team/getAllTeams",params);
//获取要件关联主体
const getBorrowerBox2 = (params) => getAction("/base/getBorrowerBox2",params);
// 查询当前机构的部门树
const getDepartmentTree = (params)=>getAction("@/sys/department/getDepartmentTree",params);
// 查询项目地址
const getFrontAddr = (params)=>getAction("@/frontAddr/get",params);

export{
    entrust,
    claimSub,
    getBorrowerBox,
    getByTypeCode,
    getByTypeCodes,
    refuseFlow,
    getMainRecordsByOid,
    fileInsert,
    deleteFile,
    ossAuth,
    treeVo,
    getUserInfo,
    getMenuTree,
    getFilesByOidAndKey,
    getTeams,
    getBorrowerBox2,
    getDepartmentTree,
    getFrontAddr
}
