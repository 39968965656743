import Vue from 'vue'
import {getByTypeCodes,getTeams} from '@/api/api'
const enhance = {
  state: {
    enhanceJs:{

    },
    code:{},
    allCodeJson:{}
  },
  mutations: {
    ADD_TABLE_ENHANCE: (state, record) => {
      if(!state.enhanceJs){
        let obj = {}
        let arr = []
        arr.push({...record})
        obj[record.code] = arr
        state.enhanceJs = obj
      }else{
        if(!state.enhanceJs[record.code]){
          let arr = []
          arr.push({...record})
          state.enhanceJs[record.code] = arr
        }
        state.enhanceJs[record.code].push({...record})
      }
      let arr = state.enhanceJs[record.code]
      while(arr.length>16){
        arr.shift()
      }
      Vue.ls.set('enhance_'+record['code'], arr)
    },
    GET_CODE_DATA: (state, record) => {
      state.code = record;
    },
    GET_ALL_CODE_DATA: (state, record) => {
      state.allCodeJson = record;
    },
  },
  actions: {
    addEhanceRecord({ commit }, record) {
      commit('ADD_TABLE_ENHANCE', record)
    },
    getAllCode({ commit }) {
      return new Promise((resolve) => {
        getByTypeCodes().then(res => {
          if(res.success){
            let newCode = {};
            res.result.forEach(item => {
              newCode[item.typeCode] = item.item;
            })
            getTeams().then(result => {
              let newYwtd = []
              if(result.success){
                result.result.forEach(item => {
                  newYwtd.push({
                    itemCode:item.id,
                    itemValue:item.teamName
                  })
                })
              }
              newCode["YWTD"] = newYwtd;
              commit('GET_ALL_CODE_DATA', newCode);
              resolve();
            })
          }
        })
      });
    },
  }
}
export default enhance