import { postAction } from '@/api/manage'

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

const login = (params)=>postAction("/sys/login",params);
const phoneLogin = (params)=>postAction("/sys/phoneLogin",params);
const logout = (params)=>postAction("/sys/logout",params);

export {
  login,
  phoneLogin,
  logout,
}