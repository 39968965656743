import Vue from "vue";
import moment from 'moment'
// import * as dayjs from "dayjs";

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  let intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') //将整数部分逢三一断
  return intPartFormat
})

// Vue.filter('dayjs', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
//   return dayjs(dataStr).format(pattern)
// })

// Vue.filter('moment', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
//   return dayjs(dataStr).format(pattern)
// })

/** 字符串超长截取省略号显示 */
Vue.filter('ellipsis', function (value, vlength = 25) {
  if (!value) {
    return "";
  }
  console.log('vlength: ' + vlength);
  if (value.length > vlength) {
    return value.slice(0, vlength) + '...'
  }
  return value
})
/*
      ENABLE("正常","ENABLE"),
      DISABLE("停用","DISABLE"),
      ARREARS("欠费","ARREARS"),
      CANCEL("注销","CANCEL"),
      FREEZE("冻结","FREEZE"),
      HIDDEN("隐藏","HIDDEN"),
    */
Vue.filter('statusFilter', value => {
  let data = ''
  switch (value) {
    case 'ENABLE':
      data = '启用'
      break;
    case 'DISABLE':
      data = '停用'
      break;
    case 'ARREARS':
      data = '欠费'
      break;
    case 'CANCEL':
      data = '注销'
      break;
    case 'FREEZE':
      data = '冻结'
      break;
    case 'HIDDEN':
      data = '隐藏'
      break;
  }
  return data
})
Vue.filter('forDatatime', value => {
  // 设置返回时间为中文
  moment.locale('zh-cn')
  // // 截图掉返回最后的Z  (Z是标准时区0，中国为东八区)
  // value = value.slice(0, value.length - 2)
  // 计算距今时间差
  // const difftime = moment().diff(moment(value), 'days')
  // if (difftime > 1) {
  //   // 大于一天 返回日期
  // return moment(value).format('YYYY-MM-DD HH:mm')
  return moment(value).format('YYYY-MM-DD')
  // } else {
  //   // 一天以内 返回多久以前
  //   return moment(value).fromNow()
  // }
})
Vue.filter('forDatatimeTime', value => {
  // 设置返回时间为中文
  moment.locale('zh-cn')
  // 计算距今时间差
  const difftime = moment().diff(moment(value), 'days')
  if (difftime > 1) {
    // 大于一天 返回日期
  return moment(value).format('YYYY-MM-DD HH:mm')
  } else {
    // 一天以内 返回多久以前
    return moment(value).fromNow()
  }
})
Vue.filter('nullReturnHorizontal', value => {
  return value?value:'——'
})



