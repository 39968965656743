import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const constantRouterMap = [
  {
    path: '*',
    redirect: '/UserManagement',
  },
  {
    path:'/UserManagement',
    meta:{
      needLogin:true
    },
    component : () => import('@/views/layout/UserManagement')
  },
  {
    path:'/datum',
    meta:{
      needLogin:true
    },
    component : () => import('@/views/strategyConfig')
  },
  {
    path:'/execute',
    meta:{
      needLogin:true
    },
    component : () => import('@/views/strategyExe')
  },
  {
    path:'/Register',
    component : () => import('@/views/user/Register')
  },
  {
    path:'/Login',
    component : () => import('@/views/user/Login')
  },
  {
    path: '/404',
    component: () => import('@/views/exception/404')
  },
  {
    path: '/service',
    component: () => import('@/views/service')
  },
]
console.log(process.env)
export default new VueRouter({
  // mode: 'history',
  mode: 'hash',
  publicPath:'/',
  base:'/',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
