import * as echarts from 'echarts/core';
import {BarChart,LineChart,PieChart} from 'echarts/charts';
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent
} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';

// 注册必须的组件
echarts.use([TitleComponent, TooltipComponent,LegendComponent, GridComponent, BarChart, CanvasRenderer, LineChart, PieChart]);
export default echarts