import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { VueAxios } from './axios'
import _this from '@/main'
// import router from './router'
import { Modal, notification } from 'ant-design-vue'
import { ACCESS_TOKEN, USER_INFO,URL_INFO } from "@/store/mutation-types"
import {getFrontAddr} from "@/api/api";
/**
 * 【指定 axios的 baseURL】
 * 如果手工指定 baseURL: '/jeecg-boot'
 * 则映射后端域名，通过 vue.config.js"
 * @type {*|string}
 */
let apiBaseUrl = process.env.VUE_APP_API_URL + '/api/cloud/'
// 创建 axios 实例
const service = axios.create({
  timeout: 9000 // 请求超时时间
})
let flag = false;
const err = (error) => {
  if (error.response) {
    let that = this;
    let data = error.response.data
    const token = _this.$getCookie(ACCESS_TOKEN)
    console.log("------异常响应------", token)
    console.log("------异常响应------", error.response.status)
    if(error.response.status===401&&!flag){
      flag = true;
      Modal.confirm({
        title: "提示",
        content: "登录已过期，请重新登录！",
        onOk(){
          flag = false;
          getFrontAddr({front:'kimdoo'}).then(res=>{
            if(res.success){
              window.location.href = res.result
            }else{
              that.$error({
                title: res.message,
                okText: "确定"
              });
            }
          })
        },
        onCancel(){
          flag = false;
        }
      })
    }else if(error.response.status!==401){
      flag = false;
    }
    return Promise.reject(error)
  }
};

service.interceptors.request.use(config => {
  if(config.url.indexOf('@')!==-1){//标记为商城接口
    config.url = apiBaseUrl + 'kimdoo' + config.url.split('@')[1]
  }else{
    config.baseURL = apiBaseUrl+"datum"
  }
  const token = _this.$getCookie(ACCESS_TOKEN)
  //var token='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MzYyNTc0NjYsInVzZXJuYW1lIjoidGVzdDExMSJ9.Wj2Axd9wwMSWCZ7GuQKrF5EIqCtblz121voa_a846Aw'
  config.headers['X-Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  return config
}, (error) => {
  return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use((response) => {
  return response.data
}, err)

const installer = {
  vm: {},
  install(Vue, router = {}) {
    Vue.use(VueAxios, router, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
