import Vue from 'vue'
import App from './App.vue'
import Storage from 'vue-ls'
import router from './router'
import store from './store/'
import '@/permission'
import { VueAxios } from "@/utils/request"
import { getText , getFiles } from "@/utils/util"
import '@/utils/filter' // base filter
/*import '@babel/polyfill'*/
import moment from 'moment'
import 'moment/locale/zh-cn';
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css';  // or 'ant-design-vue/dist/antd.less'
import echarts from '@/core/echartsComponents'
import './styles/index.css' // 导入全局样式
moment.locale('zh-cn')
import './styles/iconfont/iconfont.css'
import hasPermission from '@/utils/hasPermission'
import vueBus from '@/utils/vueBus';
import '@/assets/less/JAreaLinkage.less'
import VueAreaLinkage from 'vue-area-linkage'
import '@/assets/less/global.less'
import copy from '@/utils/means'
import globalMethods from '@/utils/globalMethods'
import cookie  from '@/utils/cookie';
import htmlToPdf from '@/utils/htmlToPdf';
import Print from 'vue-print-nb'


const storageOptions = {
  namespace: 'pro__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
};


Vue.prototype.$echarts = echarts
Vue.prototype.$admin = null
Vue.prototype.$getText = getText;
Vue.prototype.$getFiles = getFiles;
Vue.use(htmlToPdf)
Vue.use(Print)
Vue.use(Storage, storageOptions)
Vue.use(Antd)
Vue.use(VueAxios, router)
Vue.use(cookie)
Vue.use(hasPermission)
Vue.use(vueBus);
Vue.use(VueAreaLinkage);
Vue.use(globalMethods);
Vue.use(copy)
Vue.config.productionTip = false
var vue = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
export default vue
