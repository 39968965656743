// import Vue from 'vue'
const DeepCopy = (obj) => {
    if (typeof obj !== 'object') {
        console.err('拷贝参数不是对象！！');
        return
    }
    console.log(obj);
    return JSON.parse(JSON.stringify(obj))
}
export default function (Vue) {
    //添加全局API
    Vue.prototype.$copy = {
        DeepCopy
    }
}