import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'
import permission from './modules/permission'
import enhance from './modules/enhance'
import getters from './getters'
import createPersistedState from "vuex-persistedstate"


Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({ //解决vuex状态刷新问题
    storage: window.sessionStorage,
    reducer(val) { // val是state
      return {
        userInfo:val.user.info
      }
    }
  })],
  modules: {
    app,
    user,
    permission,
    enhance,
  },
  state: {
  },
  mutations: {
  },
  actions: {

  },
  getters
})
