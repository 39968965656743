import Vue from 'vue'
import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
// import {setWaterMark,removeWaterMark} from '@/utils/watermark.js'
import { getUserInfoByToken } from '@/api/api'
import { message } from 'ant-design-vue'
// import moment from 'moment'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/Login', '/Register', '/home'] // no redirect whitelist

router.beforeEach((to, from, next) => {
  next()
  // NProgress.start()
  // console.log('to', to)
  // if (to.meta.needLogin) { // 需要登录页面
  //   if (Vue.ls.get(ACCESS_TOKEN)) {
  //     if (Vue.ls.get(USER_INFO)) {
  //       next()
  //       NProgress.done()
  //     } else {
  //       getUserInfoByToken().then(res => {
  //         Vue.ls.set(USER_INFO, res.result)
  //         next()
  //         NProgress.done()
  //       })
  //     }
  //   } else {
  //     message.warning('请先登录');
  //     Vue.ls.remove(USER_INFO)
  //     next('/Login')
  //     NProgress.done()
  //   }
  // } else { // 不需要登录页面
  //   next()
  //   NProgress.done()
  // }
})

router.afterEach((to) => {
 
})
