import Vue from 'vue'
import { login, logout, phoneLogin, thirdLogin } from "@/api/login"
import { ACCESS_TOKEN, USER_NAME,USER_INFO,UI_CACHE_DB_DICT_DATA,TENANT_ID ,USER_MENU,CURRENT_PAGE} from "@/store/mutation-types"
import { welcome } from "@/utils/util"
// import { getUserInfoByToken } from '@/api/api'
import { getAction } from '@/api/manage'

const user = {
  state: {
    token: '',
    username: '',
    realname: '',
    tenantid:'',
    welcome: '',
    avatar: '',
    permissionList: [],
    info: {},
    dropdown:{},
    curDepart:'',
    roles:[],
    depart:{}
  },

  mutations: {
    SET_DEPART: (state, depart) => {
      state.depart = depart
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { username, realname, welcome }) => {
      state.username = username
      state.realname = realname
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_PERMISSIONLIST: (state, permissionList) => {
      state.permissionList = permissionList
    },
    SET_DROPDOWN: (state, dropdown) => {
      state.dropdown = dropdown
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_TENANT: (state, id) => {
      state.tenantid = id
    },
    SET_CURDEPART: (state,curDepart) => {
      state.curDepart = curDepart;
    },
    SET_ROLES: (state,roles) => {
      state.roles = roles;
    }
  },

  actions: {
    // CAS验证登录
    ValidateLogin({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        getAction("/cas/client/validateLogin",userInfo).then(response => {
          if(response.success){
            const result = response.result
            const userInfo = result.userInfo
            Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
            Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000)
            Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)

            commit('SET_TOKEN', result.token)
            commit('SET_INFO', userInfo)
            commit('SET_NAME', { username: userInfo.username,realname: userInfo.realname})
            commit('SET_AVATAR', userInfo.avatar)
            resolve(response)
          }else{
            resolve(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          if(response.code =='200'){
            const result = response.result
            const userInfo = result.userInfo
            userInfo["userRole"] = result.userRole;
            //测试用
            result.token='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MzIzMDUyNjcsInVzZXJuYW1lIjoiYWRtaW4ifQ.iN6BvHPT1YlD0EWv-S3ff2Kz9GIyDIdZU158GfPqphM'
            //测试用
            Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
            Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000)
            Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
            commit('SET_INFO', userInfo)
            commit('SET_NAME', { username: userInfo.username,realname: userInfo.realname, welcome: welcome() })
            commit('SET_CURDEPART',userInfo.curDepart);
            commit('SET_ROLES',result.userRole)
            resolve(response)
          }else{
            reject(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    //手机号登录
    PhoneLogin({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
          phoneLogin(userInfo).then(response => {
          if(response.code =='200'){
        const result = response.result
        const userInfo = result.userInfo
        Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
        Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000)
        Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
        Vue.ls.set(UI_CACHE_DB_DICT_DATA, result.sysAllDictItems, 7 * 24 * 60 * 60 * 1000)
        commit('SET_TOKEN', result.token)
        commit('SET_INFO', userInfo)
        commit('SET_NAME', { username: userInfo.username,realname: userInfo.realname, welcome: welcome() })
        commit('SET_AVATAR', userInfo.avatar)
        resolve(response)
      }else{
        reject(response)
      }
    }).catch(error => {
        reject(error)
      })
    })
    },
    // 获取用户信息
    getUserInfo() {
      return new Promise((resolve, reject) => {
        let v_token = Vue.ls.get(ACCESS_TOKEN);
        let params = {token:v_token};
        // getUserInfoByToken(params).then(response => {
        //   resolve(response)
        // }).catch(error => {
        //   reject(error)
        // })
      })
    },

    // 登出
    Logout({ commit }) {
      return new Promise((resolve) => {
        logout().then(() => {
          commit('SET_TOKEN', '')
          commit('SET_INFO', '')
          Vue.ls.remove(ACCESS_TOKEN)
          Vue.ls.remove(USER_INFO)
          Vue.ls.remove(USER_MENU)
          Vue.ls.remove(CURRENT_PAGE)
          resolve()
        }).catch(() => {
          resolve()
        })
      })
    },
    // 第三方登录
    // ThirdLogin({ commit }, token) {
    //   return new Promise((resolve, reject) => {
    //     thirdLogin(token).then(response => {
    //       if(response.code =='200'){
    //         const result = response.result
    //         const userInfo = result.userInfo
    //         Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
    //         Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000)
    //         Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
    //         commit('SET_TOKEN', result.token)
    //         commit('SET_INFO', userInfo)
    //         commit('SET_NAME', { username: userInfo.username,realname: userInfo.realname, welcome: welcome() })
    //         commit('SET_AVATAR', userInfo.avatar)
    //         resolve(response)
    //       }else{
    //         reject(response)
    //       }
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    saveTenant({ commit }, id){
      Vue.ls.set(TENANT_ID, id, 7 * 24 * 60 * 60 * 1000)
      commit('SET_TENANT', id)
    },

  }
}

export default user
