import { getByTypeCode,getByTypeCodes , getFilesByOidAndKey} from '@/api/api'
import _this from '@/main'
// 字典查询条目方法
export default {
    install(Vue) {
        //typeCode : 条目首字母大写缩写
        Vue.prototype.$itemSearch = function (typeCode) {
            return new Promise(resolve => {
                getByTypeCode({ typeCode }).then(res => {
                    if (res.success) {
                        resolve(res.result)
                    } else {
                        _this.$error({
                            title: '条目查询失败',
                            okText: "确定"
                        });
                    }
                })
            })
        },
        Vue.prototype.$getTypeCodes = function (code) {//code : 条目首字母大写缩写
            return new Promise(resolve => {
                getByTypeCodes({ code }).then(res => {
                    if (res.success) {
                        resolve(res.result)
                    } else {
                        _this.$error({
                            title: '条目查询失败',
                            okText: "确定"
                        });
                    }
                })
            })
        },
        Vue.prototype.$filterZh = function(list,code){//用于表格过滤中文 list传入条目数据数组 code表格中当前项
    
            if(list.__proto__.constructor !== Array){
                _this.$error({
                    title: '传入条目不是数组',
                    okText: "确定"
                  });
                return
            }
            let str = ''
            if(code.constructor===String){
                if(code.indexOf(',')==-1){
                    code = [code]
                }else{
                    code = code.split(',')
                }
            }
            code.forEach(item=>{
                list.forEach(it=>{
                    if(it.itemCode===item){
                        str += it.itemValue + ','
                    }
                })
            })
            return str.slice(0,str.length-1)
        },
        Vue.prototype.$getFileInfo = function(key,oid){
            if(key&&oid){
                return new Promise(resolve => {
                    getFilesByOidAndKey({ oid,key }).then(res => {
                        if (res.success) {
                            resolve(res.result)
                        } else {
                            _this.$error({
                                title: '文件查询失败',
                                okText: "确定"
                            });
                        }
                    })
                })
            }
        }
    }
}